<template>
  <b-form>
    <b-row class="d-flex mx-auto justify-content-center">
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Servidor"
          label-for="server"
        >
          <b-form-input
            id="server"
            v-model="server"
            readonly
            placeholder="Servidor"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Nombre de la instancia"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="name"
            readonly
            placeholder="Nombre de la instancia"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Puerto"
          label-for="port"
        >
          <b-form-input
            id="port"
            v-model="port"
            readonly
            placeholder="Puerto"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Notas"
          label-for="note"
        >
          <b-form-textarea
            id="note"
            v-model="note"
            readonly
            placeholder="Notas"
            rows="3"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="fields.length > 0"
      class="d-flex mx-auto justify-content-center"
    >
      <b-col
        v-for="(input, index) in fields"
        :key="`fieldsInput-${index}`"
        lg="8"
        md="10"
        sm="12"
      >
        <b-row>
          <b-col
            lg="5"
            md="5"
            sm="5"
            style="margin-bottom: 10px;"
          >
            <b-form-input
              type="text"
              v-model="input.item"
              readonly
              :placeholder="`Item ${index+1}`"
            />
          </b-col>
          <b-col
            lg="5"
            md="5"
            sm="5"
          >
            <b-form-input
              type="text"
              v-model="input.value"
              readonly
              :placeholder="`Valor ${index+1}`"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import CustomersServerInstanceService from './services/CustomersServerInstanceService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'InstanceFormComponent',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BFormTextarea,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [],
      server: '',
      name: '',
      port: '',
      note: '',
      rowsServers: [],
    }
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.server = ''
      this.name = ''
      this.port = ''
      this.note = ''
      this.rowsServers = []
      this.fields = []
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('search.server.instance.customer.show')) {
        await CustomersServerInstanceService.handleEdit(this.id).then(({ data }) => {
          this.server = data.data.server_id === null ? '' : data.data.server.name
          this.name = data.data.name === null ? '' : data.data.name
          this.port = data.data.port === null ? '' : data.data.port
          this.note = data.data.note === null ? '' : data.data.note
          this.fields = data.data.fields === null ? [] : data.data.fields
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>

<style scoped>
.width-40px {
  border-radius: 100% !important;
  height: 40px !important;
  width: 40px !important;
  position: relative !important;
}
</style>
