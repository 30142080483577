<template>
  <b-form>
    <b-row class="align-content-center mx-auto d-flex flex-column">
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Nombre"
          label-for="name"
        >
          <b-form-input
            v-model="name"
            type="text"
            readonly
            placeholder="Nombre"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Usuario"
          label-for="user"
        >
          <b-form-input
            v-model="user"
            readonly
            placeholder="Usuario"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label>Contraseña</label>
          </div>
          <b-input-group
            class="input-group-merge"
          >
            <b-form-input
              v-model="password"
              class="form-control-merge"
              :type="passwordFieldType"
              name="password"
              readonly
              placeholder="············"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Nota"
          label-for="note"
        >
          <b-form-textarea
            id="note"
            v-model="note"
            readonly
            placeholder="Nota"
            rows="3"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormTextarea,
  BFormGroup,
  BForm,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import CustomersUserService from './services/CustomersUserService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'UserFormComponent',
  components: {
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    BForm,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      name: '',
      user: '',
      password: '',
      note: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.name = ''
      this.user = ''
      this.password = ''
      this.note = ''
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('search.user.customer.show')) {
        await CustomersUserService.handleEdit(this.id).then(({ data }) => {
          this.name = data.data.name === null ? '' : data.data.name
          this.user = data.data.user === null ? '' : data.data.user
          this.password = data.data.password === null ? '' : data.data.password
          this.note = data.data.note === null ? '' : data.data.note
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>

<style scoped>

</style>
