import customersContactDefaultConfig from './CustomersContactDefaultConfig'
import axiosIns from '@/libs/axios'

class CustomersContactService {
    config = { ...customersContactDefaultConfig }

    axios = null

    constructor() {
      this.axios = axiosIns
    }

    async handleList(id) {
      const result = await this.axios.get(`${this.config.indexEndpoint}${id}`)

      return result
    }
    
    async handleEdit(id) {
      const result = await this.axios.get(`${this.config.editEndpoint}${id}`)

      return result
    }
}

export default new CustomersContactService()
