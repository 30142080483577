<template>
  <b-card-code>
    <b-row
      v-if="accessPermissions('search.customers.index')"
    >
      <b-col
        cols="12"
        sm="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Cliente"
          label-for="customer"
        >
          <v-select
            v-model="customer"
            :dir="'ltr'"
            label="full_name"
            class="b-select"
            :options="rowsCustomers"
            @input="onChangeCustomer"
          >
            <span slot="no-options">No hay data para cargar</span>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <TabsComponent
          :id="id"
        />
      </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import vSelect from 'vue-select'
import TabsComponent from './components/TabsComponent.vue'
import CustomersService from '@/pages/dashboard/customers/services/CustomersService'
import permissions from '@/libs/permissions'
import errorsServices from '@/libs/errorsServices'

export default {
  name: 'CustomerSearchAdvancedPage',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BCardCode,
    vSelect,
    TabsComponent,
  },
  data() {
    return {
      id: undefined,
      customer: '',
      rowsCustomers: [],
    }
  },
  created() {
    this.onHandleCustomersList()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    onChangeCustomer(event) {
      this.id = event === null ? undefined : event.id
    },
    async onHandleCustomersList() {
      if (this.accessPermissions('search.customers.index')) {
        await CustomersService.handleList().then(response => {
          this.rowsCustomers = response.data.data
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>

<style lang="scss">
.b-select {
  min-height: 36px !important;
  border-radius: 4px !important;
  width: auto;
}

.vs__selected-options {
  min-height: 30px !important;
}
</style>
