<template>
  <b-form>
    <b-row>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Servidor"
          label-for="server"
        >
          <b-form-input
            id="server"
            v-model="server"
            readonly
            placeholder="Servidor"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Acción"
          label-for="action"
        >
          <b-form-input
            id="action"
            v-model="action"
            readonly
            placeholder="Acción"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="IP"
          label-for="ip"
        >
          <b-form-input
            id="ip"
            v-model="ip"
            readonly
            placeholder="IP"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Protocolo"
          label-for="protocol"
        >
          <b-form-input
            id="protocol"
            v-model="protocol"
            readonly
            placeholder="Protocolo"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Puerto"
          label-for="port"
        >
          <b-form-input
            id="port"
            v-model="port"
            readonly
            placeholder="Puerto"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Notas"
          label-for="note"
        >
          <b-form-textarea
            id="note"
            v-model="note"
            readonly
            placeholder="Notas"
            rows="3"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Descripción"
          label-for="description"
        >
          <b-form-textarea
            id="description"
            v-model="description"
            readonly
            placeholder="Descripción"
            rows="3"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="fields.length > 0"
    >
      <b-col
        v-for="(input, index) in fields"
        :key="`fieldsInput-${index}`"
        lg="12"
        md="12"
        sm="12"
      >
        <b-row>
          <b-col
            lg="6"
            md="6"
            sm="6"
            style="margin-bottom: 10px;"
          >
            <b-form-input
              type="text"
              v-model="input.item"
              readonly
              :placeholder="`Item ${index+1}`"
            />
          </b-col>
          <b-col
            lg="6"
            md="6"
            sm="6"
          >
            <b-form-input
              type="text"
              v-model="input.value"
              readonly
              :placeholder="`Valor ${index+1}`"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import CustomersServerFirewallService from './services/CustomersServerFirewallService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'FirewallFormComponent',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BFormTextarea,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [],
      server: '',
      action: '',
      ip: '',
      protocol: '',
      port: '',
      description: '',
      note: '',
      rowsServers: [],
    }
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.server = ''
      this.action = ''
      this.ip = ''
      this.protocol = ''
      this.port = ''
      this.description = ''
      this.note = ''
      this.rowsServers = []
      this.fields = []
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('search.server.firewall.customer.show')) {
        await CustomersServerFirewallService.handleEdit(this.id).then(({ data }) => {
          this.server = data.data.server_id === null ? '' : data.data.server.name
          this.action = data.data.action === null ? '' : data.data.action
          this.ip = data.data.ip === null ? '' : data.data.ip
          this.protocol = data.data.protocol === null ? '' : data.data.protocol
          this.port = data.data.port === null ? '' : data.data.port
          this.description = data.data.description === null ? '' : data.data.description
          this.note = data.data.note === null ? '' : data.data.note
          this.fields = data.data.fields === null ? [] : data.data.fields
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>

<style scoped>
.width-40px {
  border-radius: 100% !important;
  height: 40px !important;
  width: 40px !important;
  position: relative !important;
}
</style>
