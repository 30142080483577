<template>
  <b-form>
    <b-row class="align-content-center mx-auto d-flex flex-column">
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Nombre completo"
          label-for="full_name"
        >
          <b-form-input
            id="full_name"
            v-model="full_name"
            readonly
            placeholder="Nombre completo"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="endPoint === 'customers'"
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Identificación"
          label-for="dni"
        >
          <b-form-input
            id="dni"
            v-model="dni"
            readonly
            placeholder="Identificación"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Email"
          label-for="email"
        >
          <b-form-input
            v-model="emailValue"
            readonly
            placeholder="Email"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Tipo"
          label-for="type"
        >
          <b-form-input
            v-model="type"
            type="email"
            readonly
            placeholder="Tipo"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="fields.length > 0"
      class="align-content-center mx-auto d-flex flex-column"
    >
      <b-col
        v-for="(input, index) in fields"
        :key="`fieldsInput-${index}`"
        lg="8"
        md="10"
        sm="12"
      >
        <b-row>
          <b-col
            lg="12"
            md="12"
            sm="12"
            cols="12"
            style="margin-bottom: 10px;"
          >
            <label>Teléfono {{index+1}}</label>
            <b-form-input
              type="number"
              v-model="input.phone"
              readonly
              :placeholder="`Teléfono ${index+1}`"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="align-content-center mx-auto d-flex flex-column">
      <b-col
        v-if="type.title === 'Persona Natural'"
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Celular"
          label-for="mobile"
        >
          <b-form-input
            id="mobile"
            v-model="mobile"
            readonly
            placeholder="Celular"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Dirección"
          label-for="address"
        >
          <b-form-textarea
            id="address"
            v-model="address"
            readonly
            placeholder="Dirección"
            rows="3"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="endPoint === 'branches'"
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Nota"
          label-for="note"
        >
          <b-form-textarea
            id="note"
            v-model="note"
            readonly
            placeholder="Nota"
            rows="3"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormTextarea,
  BFormGroup,
  BForm,
  BFormInput,
} from 'bootstrap-vue'
import router from '@/router'
import CustomersService from '../services/CustomersService'
import CustomersBranchService from '../services/CustomersBranchService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'FormCreateComponent',
  components: {
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    BForm,
    BFormInput,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: false,
    },
    isActive: {
      type: Boolean,
      required: false,
    },
    root: {
      type: String,
      required: true,
    },
    endPoint: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      fields: [{ phone: '' }],
      full_name: '',
      dni: '',
      emailValue: '',
      mobile: '',
      type: '',
      address: '',
      note: '',
    }
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
    customer() {
    },
    async isActive() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.fields = [{ phone: '' }]
      this.full_name = ''
      this.dni = ''
      this.emailValue = ''
      this.mobile = ''
      this.address = ''
      this.note = ''
      this.type = ''
    },
    closeForm() {
      this.clearForm()
      if (this.root === 'customers') {
        router.push({ name: 'customers' })
      } else {
        this.$emit('setHiddenModal')
      }
    },
    async onHandleEdit() {
      if (this.isActive && this.id !== undefined) {
        if (this.endPoint === 'customers' && this.accessPermissions('customers.show')) {
          await CustomersService.handleEdit(this.id).then(({ data }) => {
            this.full_name = data.data.full_name
            this.dni = data.data.dni
            this.emailValue = data.data.email
            this.mobile = data.data.mobile
            this.address = data.data.address
            this.note = data.data.note
            this.type = data.data.type === 'Persona Natural' ? 'Persona Natural' : 'Empresa'
            this.fields = (data.data.phone.length === 0 || data.data.phone === null) ? [{phone: ''}] : data.data.phone
          }).catch(error => this.errorResp(error))
        } else {
          await CustomersBranchService.handleEdit(this.id).then(({ data }) => {
            this.full_name = data.data.full_name
            this.dni = data.data.dni
            this.emailValue = data.data.email
            this.mobile = data.data.mobile
            this.address = data.data.address
            this.note = data.data.note
            this.type = data.data.type === 'Persona Natural' ? 'Persona Natural' : 'Empresa'
            this.fields = (data.data.phone.length === 0 || data.data.phone === null) ? [{phone: ''}] : data.data.phone
          }).catch(error => this.errorResp(error))
        }
      }
    },
  },
}
</script>

<style scoped>
.width-40px {
  border-radius: 100% !important;
  height: 40px !important;
  width: 40px !important;
  position: relative !important;
}
</style>
