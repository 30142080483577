<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <b-form-input
              v-if="accessPermissions('search.server.rdps.customer.list.index')"
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Buscar..."
              class="d-inline-block"
            />
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="accessPermissions('search.server.rdps.customer.list.index')">
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fields"
          :items="rows"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #cell(file)="data">
            <div v-if="data.item.file.length > 0">
              <div
                v-for="(file, index) in data.item.file"
                :key="index"
              >
                <a
                  :href="file.value"
                  :download="file.value"
                  target="_blank"
                >
                  File {{ index+1 }}
                </a>
              </div>
            </div>
          </template>

          <template #cell(action)="data">
            <feather-icon
              icon="EyeIcon"
              class="mx-auto text-center"
              @click="onHandleEdit(data.item.id)"
            />
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="accessPermissions('search.server.rdps.customer.list.index')">
      <b-col cols="6">
        <div class="custom-search d-flex justify-content-start">
          <b-form-group
            label="Por Página"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="custom-search d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
    <b-modal
      v-if="accessPermissions('search.server.rdps.customer.show')"
      id="modal-server-rdps"
      ref="server-rdps-modal"
      centered
      hide-backdrop
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      size="lg"
      title="Agregar un RDPS"
      @hidden="hide"
    >
      <RdpsFormComponent
        :id="id"
        :customer="customer"
        @setHiddenModal="hide"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BPagination,
  BFormInput,
  BFormSelect,
  BTable,
  BModal,
} from 'bootstrap-vue'
import RdpsFormComponent from './RdpsFormComponent.vue'
import CustomersServerRdpsService from './services/CustomersServerRdpsService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'
import { getItem, setItem } from '@/libs/storage'

export default {
  name: 'RdpsComponent',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BFormInput,
    BFormSelect,
    BTable,
    BModal,
    RdpsFormComponent,
  },
  props: {
    customer: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 20, 50, 100],
      totalRows: 0,
      currentPage: 1,
      filter: null,
      fields: [
        {
          key: 'server_name', label: 'Servidor',
        },
        {
          key: 'name', label: 'Cliente',
        },
        {
          key: 'note', label: 'Notas',
        },
        {
          key: 'file', label: 'Path',
        },
        {
          key: 'action', label: 'Acción',
        },
      ],
      rows: [],
      id: undefined,
    }
  },
  watch: {
    filter(value) {
      setItem('search_customers_advanced_servers_rdps', value)
    },
    async customer() {
      await this.onHandleList()
    },
    async isActive() {
      await this.onHandleList()
    },
  },
  mounted() {
    this.filter = getItem('search_customers_advanced_servers_rdps') === undefined ? '' : getItem('search_customers_advanced_servers_rdps')
  },
  async created() {
    await this.onHandleList()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    hide() {
      this.id = undefined
      this.onHandleList()
      this.$refs['server-rdps-modal'].hide()
    },
    clear() {
      this.rows = []
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async onHandleEdit(id) {
      if (this.accessPermissions('search.server.rdps.customer.show')) {
        this.id = id
        this.$refs['server-rdps-modal'].show()
      }
    },
    async onHandleList() {
      this.clear()
      if (this.isActive && this.customer !== undefined && this.accessPermissions('search.server.rdps.customer.index')) {
        await CustomersServerRdpsService.handleList(this.customer).then(({ data }) => {
          let arrayList = []

          if (data.data.length > 0) {
            const array = data.data

            array.map(({ server_id, server }, index) => {
              array[index].server_name = server_id === null ? '' : server.name
            })

            arrayList = array
          }

          this.rows = arrayList.length === 0 ? [] : arrayList
          this.totalRows = arrayList.length
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>

<style scoped>

</style>
