<template>
  <b-form>
    <b-row class="align-content-center mx-auto d-flex flex-column">
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Nombre ó título del documento"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="name"
            readonly
            placeholder="Nombre ó título del documento"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="id === undefined"
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Los archivos adjuntos"
          label-for="files"
        >
          <b-form-file
            v-model="files"
            multiple
            placeholder="Elija un archivo o suéltelo aquí..."
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Notas"
          label-for="note"
        >
          <b-form-textarea
            id="note"
            v-model="note"
            readonly
            placeholder="Notas"
            rows="3"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="arrayFiles.length > 0"
      class="align-content-center mx-auto d-flex flex-column"
    >
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-row>
          <b-col
            v-for="(file, index) in arrayFiles"
            :key="index"
            sm="12"
          >
            <a
              :href="file.value"
              :download="file.value"
              target="_blank"
            >
              File {{ index+1 }}
            </a>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BFormFile,
} from 'bootstrap-vue'
import CustomersDocumentService from './services/CustomersDocumentService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'DocumentFormComponent',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BFormFile,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      note: '',
      files: null,
      arrayFiles: [],
    }
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.name = ''
      this.note = ''
      this.files = null
      this.arrayFiles = []
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('search.document.customer.show')) {
        await CustomersDocumentService.handleEdit(this.id).then(({ data }) => {
          this.name = data.data.name === null ? '' : data.data.name
          this.note = data.data.note === null ? '' : data.data.note
          this.arrayFiles = data.data.file.length === 0 ? [] : data.data.file
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>

<style scoped>

</style>
