import customerDefaultConfig from './CustomersServerUserDefaultConfig'
import axiosIns from '@/libs/axios'

class CustomersServerUserService {
  config = { ...customerDefaultConfig }
  
  axios = null
  
  constructor() {
    this.axios = axiosIns
  }
  
  async handleList(customer) {
    const result = await this.axios.get(`${this.config.indexEndpoint}${customer}`)
    
    return result
  }
  
  async handleEdit(id) {
    const result = await this.axios.get(`${this.config.editEndpoint}${id}`)
    
    return result
  }
}

export default new CustomersServerUserService()
