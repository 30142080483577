<template>
  <b-form>
    <b-row class="align-content-center mx-auto d-flex flex-column">
      <b-col
        md="11"
        sm="12"
      >
        <b-form-group
          label="Sucursal"
          label-for="branch_id"
        >
          <b-form-input
            id="branch_id"
            v-model="branch_id"
            readonly
            placeholder="Sucursal"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="11"
        sm="12"
      >
        <b-form-group
          label="Nombre completo"
          label-for="fullname"
        >
          <b-form-input
            id="fullname"
            v-model="fullname"
            readonly
            placeholder="Nombre completo"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="11"
        sm="12"
      >
        <b-form-group
          label="Email"
          label-for="email"
        >
          <b-form-input
            id="emailValue"
            v-model="emailValue"
            readonly
            placeholder="Email"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="11"
        sm="12"
      >
        <b-form-group
          label="Teléfono"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            v-model="phone"
            readonly
            placeholder="Teléfono"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="11"
        sm="12"
      >
        <b-form-group
          label="Puesto"
          label-for="position"
        >
          <b-form-input
            id="position"
            v-model="position"
            readonly
            placeholder="Puesto"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="11"
        sm="12"
      >
        <b-form-group
          label="Nota"
          label-for="note"
        >
          <b-form-textarea
            id="note"
            v-model="note"
            readonly
            placeholder="Nota"
            rows="3"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="11"
        sm="12"
      >
        <b-form-group
          label="Encargado"
          label-for="notify"
        >
          <b-form-checkbox
            v-model="notify"
            readonly
            class="custom-control-primary"
          >
            Si
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
} from 'bootstrap-vue'
import CustomersContactService from '../services/CustomersContactService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'CustomersContactFormComponent',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
  },
  props: {
    customerId: {
      type: Number,
      required: true,
    },
    contactId: {
      type: Number,
      required: false,
    },
    chedkbox: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      fullname: '',
      emailValue: '',
      phone: '',
      position: '',
      note: '',
      notify: this.chedkbox,
      branch_id: '',
      rowsBranches: [],
    }
  },
  watch: {
    async customerId() {
      await this.onHandleEdit(this.contactId)
    },
    async contactId() {
      await this.onHandleEdit(this.contactId)
    },
  },
  async created() {
    await this.onHandleEdit(this.contactId)
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.fullname = ''
      this.emailValue = ''
      this.phone = ''
      this.position = ''
      this.note = ''
      this.branch_id = ''
      this.notify = false
      this.rowsBranches = []
      this.$emit('setHiddenModal')
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit(id) {
      if (this.customerId !== undefined && this.contactId !== undefined && this.accessPermissions('search.customer.contact.show')) {
        await CustomersContactService.handleEdit(id).then(({ data }) => {
          this.fullname = data.data.fullname
          this.emailValue = data.data.email
          this.phone = data.data.phone
          this.position = data.data.position
          this.note = data.data.note
          this.branch_id = data.data.customer_branch_id === null ? '' : data.data.customer_branch.full_name
          this.notify = data.data.notify === 'Si' ? true : false
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>

<style scoped>
</style>
