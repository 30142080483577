<template>
  <b-row>
    <b-col cols="12">
      <b-tabs justified>
        <b-tab
          v-if="accessPermissions('search.customers.show')"
          :active="activeTabName === 'data' ? true : false"
          @click="handleActiveTab('data')"
        >
          <template #title>
            <span>Datos</span>
          </template>

          <DataComponent
            :customer="customer"
            :isActive="activeTabName === 'data' ? true : false"
          />
        </b-tab>

        <b-tab
          v-if="accessPermissions('search.tab.data.branches.customer.index')"
          :active="activeTabName === 'branches' ? true : false"
          :disabled="customer === undefined ? true : false"
          @click="handleActiveTab('branches')"
        >
          <template #title>
            <span>Sucursales</span>
          </template>

          <BranchComponent
            v-if="customer !== undefined"
            :customer="customer"
            :isActive="activeTabName === 'branches' ? true : false"
          />
        </b-tab>

        <b-tab
          v-if="accessPermissions('search.tab.data.contacts.customer.index')"
          :active="activeTabName === 'contacts' ? true : false"
          :disabled="customer === undefined ? true : false"
          @click="handleActiveTab('contacts')"
        >
          <template #title>
            <span>Contactos</span>
          </template>

          <ContactComponent
            v-if="customer !== undefined"
            :id="customer"
            :isActive="activeTabName === 'contacts' ? true : false"
          />
        </b-tab>

        <b-tab
          v-if="accessPermissions('search.tab.data.providers.customer.index')"
          :active="activeTabName === 'providers' ? true : false"
          :disabled="customer === undefined ? true : false"
          @click="handleActiveTab('providers')"
        >
          <template #title>
            <span>Proveedores</span>
          </template>

          <ProviderComponent
            v-if="customer !== undefined"
            :customer="customer"
            :isActive="activeTabName === 'providers' ? true : false"
          />
        </b-tab>

        <b-tab
          v-if="accessPermissions('search.tab.data.users.customer.index')"
          :active="activeTabName === 'users' ? true : false"
          :disabled="customer === undefined ? true : false"
          @click="handleActiveTab('users')"
        >
          <template #title>
            <span>Usuarios</span>
          </template>

          <UserComponent
            v-if="customer !== undefined"
            :customer="customer"
            :isActive="activeTabName === 'users' ? true : false"
          />
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import DataComponent from './GeneralData/Data/DataComponent.vue'
import BranchComponent from './GeneralData/Branch/BranchComponent.vue'
import ContactComponent from './GeneralData/Contact/ContactComponent.vue'
import ProviderComponent from './GeneralData/Provider/ProviderComponent.vue'
import UserComponent from './GeneralData/User/UserComponent.vue'
import permissions from '@/libs/permissions'

export default {
  name: 'TabGeneralDataComponent',
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    DataComponent,
    BranchComponent,
    ContactComponent,
    ProviderComponent,
    UserComponent,
  },
  props: {
    customer: {
      type: [String, Number],
      required: false,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      activeTabName: 'data',
    }
  },
  watch: {
    customer() {
    },
    isActive() {
      this.handleActiveTab(this.activeTabName)
    },
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    handleActiveTab(value) {
      this.activeTabName = value
    },
  },
}
</script>

<style scoped>

</style>
