<template>
  <b-form>
    <b-row class="align-content-center mx-auto d-flex flex-column">
      <b-col
        v-if="id !== undefined"
        sm="12"
        class="align-content-right d-flex flex-column"
      >
        <div class="position-relative d-inline text-right">
          <h6>Creado: </h6>
        </div>
        <div class="position-relative d-inline text-right">
          <h4><strong>{{ created_at }}</strong></h4>
        </div>
      </b-col>
      <b-col
        sm="12"
      >
        <b-form-group
          label="Descripción"
          label-for="description"
        >
          <quill-editor
            id="description"
            v-model="description"
            readonly
            :options="editorOption"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'
import CustomersNoteService from './services/CustomersNoteService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'
import moment from 'moment'

export default {
  name: 'NoteFormComponent',
  components: {
    quillEditor,
    BRow,
    BCol,
    BFormGroup,
    BForm,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: ['serif', 'monospace'] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
          ],
        },
        theme: 'snow',
        placeholder: 'Inserte texto aquí...',
      },
      description: '',
      created_at: '',
    }
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.description = ''
      this.created_at = ''
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('search.note.customer.show')) {
        await CustomersNoteService.handleEdit(this.id).then(({ data }) => {
          const dateCreated = data.data.created_at.split(' ')
          this.created_at = moment(dateCreated[0]).format('DD/MM/YYYY')
          this.description = data.data.description === null ? '' : data.data.description
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
