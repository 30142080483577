<template>
  <b-form>
    <b-row>
      <b-col sm="12">
        <b-form-group
          label="Nombre del servidor"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="name"
            readonly
            placeholder="Servidor"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Ip pública"
          label-for="ip_public"
        >
          <b-form-input
            id="ip_public"
            v-model="ip_public"
            readonly
            placeholder="Ip pública"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Ip privada"
          label-for="ip_private"
        >
          <b-form-input
            id="ip_private"
            v-model="ip_private"
            readonly
            placeholder="Ip privada"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Sistema operativo"
          label-for="so"
        >
          <b-form-input
            id="so"
            v-model="so"
            readonly
            placeholder="Sistema operativo"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Memoria"
          label-for="memory"
        >
          <b-form-input
            id="memory"
            v-model="memory"
            readonly
            placeholder="Memoria"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Disco"
          label-for="disc"
        >
          <b-form-input
            id="disc"
            v-model="disc"
            readonly
            placeholder="Disco"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Modelo del servidor"
          label-for="model"
        >
          <b-form-input
            id="model"
            v-model="model"
            readonly
            placeholder="Modelo del servidor"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="12"
        md="12"
        sm="12"
      >
        <b-form-group
          label="RDWeb"
          label-for="rdweb"
        >
          <b-form-input
            id="rdweb"
            v-model="rdweb"
            readonly
            placeholder="RDWeb"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Dominio"
          label-for="domain"
        >
          <b-form-input
            id="domain"
            v-model="domain"
            readonly
            placeholder="Dominio"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="CPU"
          label-for="cpu"
        >
          <b-form-input
            id="cpu"
            v-model="cpu"
            readonly
            placeholder="CPU"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="12"
        md="12"
        sm="12"
      >
        <b-form-group
          label="Notas"
          label-for="note"
        >
          <b-form-textarea
            id="note"
            v-model="note"
            readonly
            placeholder="Notas"
            rows="3"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="fields.length > 0"
    >
      <b-col
        v-for="(input, index) in fields"
        :key="`fieldsInput-${index}`"
        lg="12"
        md="12"
        sm="12"
      >
        <b-row>
          <b-col
            lg="6"
            md="6"
            sm="6"
            style="margin-bottom: 10px;"
          >
            <b-form-input
              type="text"
              v-model="input.item"
              readonly
              :placeholder="`Item ${index+1}`"
            />
          </b-col>
          <b-col
            lg="6"
            md="6"
            sm="6"
          >
            <b-form-input
              type="text"
              v-model="input.value"
              readonly
              :placeholder="`Valor ${index+1}`"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import CustomersServerService from './services/CustomersServerService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'ServerFormComponent',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BFormTextarea,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [],
      name: '',
      ip_public: '',
      ip_private: '',
      so: '',
      memory: '',
      disc: '',
      model: '',
      rdweb: '',
      domain: '',
      cpu: '',
      note: '',
    }
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.name = ''
      this.ip_public = ''
      this.ip_private = ''
      this.so = ''
      this.memory = ''
      this.disc = ''
      this.model = ''
      this.rdweb = ''
      this.domain = ''
      this.cpu = ''
      this.note = ''
      this.fields = []
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('search.server.customer.show')) {
        await CustomersServerService.handleEdit(this.id).then(({ data }) => {
          this.name = data.data.name === null ? '' : data.data.name
          this.ip_public = data.data.ip_public === null ? '' : data.data.ip_public
          this.ip_private = data.data.ip_private === null ? '' : data.data.ip_private
          this.so = data.data.so === null ? '' : data.data.so
          this.memory = data.data.memory === null ? '' : data.data.memory
          this.disc = data.data.disc === null ? '' : data.data.disc
          this.model = data.data.model === null ? '' : data.data.model
          this.rdweb = data.data.rdweb === null ? '' : data.data.rdweb
          this.domain = data.data.domain === null ? '' : data.data.domain
          this.cpu = data.data.cpu === null ? '' : data.data.cpu
          this.note = data.data.note === null ? '' : data.data.note
          this.fields = data.data.fields === null ? [] : data.data.fields
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>

<style scoped>
.width-40px {
  border-radius: 100% !important;
  height: 40px !important;
  width: 40px !important;
  position: relative !important;
}
</style>
