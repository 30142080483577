import { render, staticRenderFns } from "./TabRemoteComponent.vue?vue&type=template&id=bb03ca54&scoped=true&"
import script from "./TabRemoteComponent.vue?vue&type=script&lang=js&"
export * from "./TabRemoteComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb03ca54",
  null
  
)

export default component.exports