<template>
  <b-form>
    <b-row class="align-content-center mx-auto d-flex flex-column">
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Proveedor"
          label-for="service_design_id"
        >
          <b-form-input
            id="service_design_id"
            v-model="service_design_id"
            readonly
            placeholder="Proveedor"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Número de contrato"
          label-for="document"
        >
          <b-form-input
            id="document"
            v-model="document"
            readonly
            placeholder="Número de contrato"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Comentario"
          label-for="note"
        >
          <b-form-textarea
            id="note"
            v-model="note"
            readonly
            placeholder="Comentario"
            rows="3"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormTextarea,
  BFormGroup,
  BForm,
  BFormInput,
} from 'bootstrap-vue'
import CustomersProviderService from './services/CustomersProviderService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'ProviderFormComponent',
  components: {
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    BForm,
    BFormInput,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      service_design_id: '',
      document: '',
      note: '',
      rowsServices: [],
    }
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.service_design_id = ''
      this.document = ''
      this.note = ''
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('search.providers.services.customer.show')) {
        await CustomersProviderService.handleEdit(this.id).then(({ data }) => {
          this.service_design_id = data.data.service_design_id === null ? '' : data.data.service_design
          this.document = data.data.document === null ? '' : data.data.document
          this.note = data.data.note === null ? '' : data.data.note
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>

<style scoped>

</style>
