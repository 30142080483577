<template>
  <b-form>
    <b-row class="align-content-center mx-auto d-flex flex-column">
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Tipo de acceso"
          label-for="device"
        >
          <b-form-input
            id="access"
            v-model="access"
            readonly
            placeholder="Tipo de acceso"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Nombre"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="name"
            readonly
            placeholder="Nombre"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="8"
        md="10"
        sm="12"
      >
        <b-form-group
          label="Características"
          label-for="features"
        >
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="fields.length > 0"
      class="align-content-center mx-auto d-flex flex-column"
    >
      <b-col
        v-for="(input, index) in fields"
        :key="`fieldsInput-${index}`"
        lg="8"
        md="10"
        sm="12"
      >
        <b-row>
          <b-col
            lg="6"
            md="6"
            sm="6"
            style="margin-bottom: 10px;"
          >
            <b-form-input
              type="text"
              v-model="input.item"
              readonly
              :placeholder="`Item ${index+1}`"
            />
          </b-col>
          <b-col
            lg="6"
            md="6"
            sm="6"
          >
            <b-form-input
              type="text"
              v-model="input.value"
              readonly
              :placeholder="`Valor ${index+1}`"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
} from 'bootstrap-vue'
import CustomersAccessService from './services/CustomersAccessService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'AccessFormComponent',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [],
      access: '',
      name: '',
    }
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    addField() {
      this.fields.push({ item: '', value: '' })
    },
    removeField(number) {
      this.fields.forEach((item, index, map) => {
        if (index === number) map.splice(index, 1)
      })
    },
    clearForm() {
      this.access = ''
      this.name = ''
      this.fields = []
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('access.customer.show')) {
        await CustomersAccessService.handleEdit(this.id).then(({ data }) => {
          this.access = data.data.access_design_id === null ? '' : data.data.access.name
          this.name = data.data.name === null ? '' : data.data.name
          this.fields = data.data.fields === null ? [] : data.data.fields
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>

<style scoped>
.width-40px {
  border-radius: 100% !important;
  height: 40px !important;
  width: 40px !important;
  position: relative !important;
}
</style>
